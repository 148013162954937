import styled from '@emotion/styled';
import {graphql, Link} from 'gatsby';
import React from 'react';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {rhythm, scale} from '../utils/typography';

interface Props {
  data: {
    markdownRemark: any;
  };
  pageContext: any;
}

const PostTemplate = ({data, pageContext}: Props) => {
  const post = data.markdownRemark;
  const {previous, next} = pageContext;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <H1>{post.frontmatter.title}</H1>
      <P>{post.frontmatter.date}</P>
      <div dangerouslySetInnerHTML={{__html: post.html}} />
      <hr style={{marginBottom: rhythm(1)}} />
      <Bio />

      <Ul>
        {previous && (
          <li>
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          </li>
        )}
        {next && (
          <li>
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          </li>
        )}
      </Ul>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;

const H1 = styled.h1`
  margin-top: ${rhythm(1)};
  margin-bottom: 0;
`;

const P = styled.p`
  ${scale(-1 / 5)};
  display: block;
  margin-bottom: ${rhythm(1)};
`;

const Ul = styled.ul`
          display: flex,
          flex-wrap: wrap,
          justify-content: space-between,
          list-style: none,
          padding: 0,
        `;
